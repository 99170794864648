/*------------------------------------
	blog sidebar
------------------------------------*/
.blog-sidebar {
	@include widther(992px) {
		padding-left: 45px;
	}

	@include media-query(991px) {
		margin-top: 80px;
		max-width: 400px;
	}

	@include media-query(767px) {
		margin-top: 60px;
		max-width: 400px;
	}

	.widget {

		h3 {
			font-size: 23px;
			font-size: calc-rem-value(23);
			color: $dark-gray;
			margin: 0 0 1.3em;
			position: relative;
			text-transform: capitalize;
			font-family: $heading-font;
			padding-bottom: 20px;
			font-weight: 700;

			&:before {
				content: "";
				background-color: $theme-primary-color;
				width: 55px;
				height: 4px;
				position: absolute;
				left: 0;
			    bottom: 0;
				border-radius: 10px;
			}
			&:after {
				content: "";
				background-color: #f2f2f2;
				width: 80%;
				height: 4px;
				position: absolute;
				left: 65px;
			    bottom: 0;
				border-radius: 10px;
			}
		}
	}

	> .widget + .widget {
		margin-top: 65px;
	}


	/*** search-widget ***/
	.search-widget {
		form div {
			position: relative;
		}

		input {
			background-color: transparentize($theme-primary-color, 0.85);
			height: 70px;
			font-size: 16px;
			font-size: calc-rem-value(16);
			padding: 6px 50px 6px 20px;
			border: 0;
			border-radius: 5px;
			box-shadow: none;
			@include transition-time(0.3s);
		}

		input:focus {
			background-color: transparentize($theme-primary-color, 0.9);
		}

		form button {
			background: transparent;
			font-size: 20px;
			font-size: calc-rem-value(20);
			color: $white;
			background-color: $theme-primary-color;
			border: 0;
			outline: 0;
			position: absolute;
			right: 10px;
			top: 52%;
			height: 50px;
			line-height: 50px;
			width: 50px;
			border-radius: 6px;
			@include translatingY();
		}
	}


	/*** about-widget ***/
	.about-widget {
		text-align: center;
		background-color: $section-bg-color;
		padding: 45px;
		position: relative;
		z-index: 1;

		@media(max-width:1200px){
          padding: 35px;
		}

		.aw-shape{
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
		}
		.img-holder {
			margin-bottom: 25px;
            
			img{
				border-radius: 50%;
				width: 100%;
				max-width: 200px;
			}

		}

		ul{
			list-style: none;
			display: flex;
			justify-content: center;

			li{
				margin-right: 10px;

				&:last-child{
					margin-right: 0;
				}
                a{
					text-decoration: none;
					display: block;
					width: 40px;
					height: 40px;
					line-height: 40px;
					background-color: $white;
					border-radius: 50%;
				}
			}
		}

		h4{
			font-weight: 700;
			margin-bottom: 15px;
		}

		p {
			font-size: 15px;
			font-size: calc-rem-value(15);
			margin-bottom: 1.2em;
		}

		a {
			font-family: $heading-font;
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $heading-color;
			text-decoration: underline;
		}

		a:hover {
			color: $theme-primary-color;
		}
	}


	/*** category-widget ***/
	.category-widget {
		ul {
			list-style: none;
		}

		ul li {
			font-size: 16px;
			font-size: calc-rem-value(16);
			position: relative;

			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}

			span {
				position: absolute;
				right: 0;
			}
		}

		ul > li + li {
			margin-top: 12px;
			padding-top: 12px;
		}

		ul a {
			display: block;
			color: #474f62;
			font-family: $heading-font;


			span{
				display: inline-block;
				width: 30px;
				height: 30px;
				line-height: 30px;
				background-color: $section-bg-color;
				text-align: center;
				border-radius: 50%;
				font-size: 18px;

				@include media-query(767px) {
					font-size: 14px;
					font-size: calc-rem-value(14);
				}
			}
		}

		ul a:hover,
		ul li:hover:before {
			color: darken($theme-primary-color, 9%);
		}
	}


	/*** recent-post-widget ***/
	.recent-post-widget {
		.post {
			overflow: hidden;
		}

		.posts > .post + .post {
			margin-top: 15px;
			padding-top: 15px;
		}

		.post .img-holder {
			width: 90px;
			float: left;

			img {
				border-radius: 6px;
				width: 100%;
				height: 80px;
				object-fit: cover;
			}
		}

		.post .details {
			width: calc(100% - 90px);
			float: left;
			padding-left: 20px;
		}

		.post h4 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			font-weight: 600;
			line-height: 1.3em;
			margin: 0 0 0.3em;
			font-family: $heading-font;

			@include media-query(1199px) {
				margin: 0;
			}

			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}
		}

		.post h4 a {
			display: inline-block;
			color: $heading-color;
		}

		.post h4 a:hover {
			color: $theme-primary-color;
		}

		.post .details .date {
			font-size: 13px;
			font-size: calc-rem-value(13);
			color: $text-color;

			i {
				display: inline-block;
				padding-right: 7px;
			}
		}
	}


	/*** instagram-widget ***/

	.wpo-instagram-widget{
        
		ul{
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			li{
			 -ms-flex: 0 0 33.33%;
			 -webkit-box-flex: 0;
			 flex: 0 0 33.33%;
			 max-width: 33.33%;
			 margin-bottom: 5px;
			 padding: 0px 3px;
 
			 img{
				 width: 100%;
				 border-radius: 6px;
			 }
			}
		}
	 
	 }


	/*** tag-widget ***/
	.tag-widget {

		ul {
			overflow: hidden;
			list-style: none;
		}

		ul li {
			float: left;
			margin: 0 8px 8px 0;
		}

		ul li a {
			font-size: 15px;
			font-size: calc-rem-value(15);
			display: inline-block;
			padding: 5px 18px;
			color: $dark-gray;
			background: #ecf4fb;
			border-radius: 5px;
		}

		ul li a:hover {
			background: $theme-primary-color;
			color: $white;
		}
	}

	/*** wpo-contact-widget ***/

	.wpo-contact-widget{
		border: 0;
		background: $dark-gray;
		padding: 30px 40px;

		@media(max-width:1200px){
			padding: 20px;
		}
	
		h2{
			font-size: 36px;
			font-weight: 700;
			text-align: left;
			color: $white;
			margin-bottom: 20px;
	
			&::before{
				left: 28px;
			}
		}
	
		p{
			color: $white;
			font-size: 18px;
		}
	
	
		a{
			display:inline-block;
			padding: 10px 20px;
			border: 1px solid $white;
			font-size: 18px;
			color: $white;
			padding-right: 90px;
			position: relative;
			margin-top: 10px;
	
			&::before{
				font-family: "themify";
				content: "\e628";
				font-size: 18px;
				position: absolute;
				right: 15px;
				top: 50%;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
	}
}