/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    background:$dark-gray;
    position: relative;
    font-size: 15px;
    overflow: hidden;

    ul {
        list-style: none;
    }

    p{
        color: $white;
    }
    li{
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer{
        padding: 70px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer{
            padding: 50px 0 0;
        }
    }
    
    @media (max-width: 767px) {
    
        .wpo-upper-footer{
            padding: 60px 0 0;
        }
    }
    
    @media (max-width: 991px) {
    
        .wpo-upper-footer .col{
            min-height: 235px;
            margin-bottom: 70px;
        }
    }
    
    @media (max-width: 767px) {
    
        .wpo-upper-footer .col{
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title{
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title{
            margin-bottom: 20px;
        }
    }


    .widget-title{
        h3{
            font-size: 22px;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 700;
        }
    }
    
    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p{
            margin-bottom: 0.8em;
            line-height: 1.9em;
            font-size: 15px;

            &:last-child{
                margin-bottom: 0;
            }
        }

        .logo{
            img{
                max-width: 250px;
            }
        }
    }
    .wpo-service-link-widget{
        padding-left: 70px;
    }
        
    .link-widget {
        overflow: hidden;
        

        @media screen and (min-width: 1200px) {
            padding-left: 75px;
        }
        
        @media (max-width: 1199px) {
            padding-left: 20px;
        }
        
        @media (max-width: 991px) {
            padding-left: 0;
        }
        
        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul{
            li{
                
                a{
                    color: #e8eaf3;
                    font-size: 15px;
                    position: relative;
                    padding-left: 20px;
                    
                    &:hover{
                        color: $theme-primary-color;
                    }
                    &:before{
                        position: absolute;
                        left: 0;
                        top: -2px;
                        content: "\f101";
                        font-family: "FontAwesome";
                    }
                }
            }
            li+li{
              padding-top: 15px;
            }
        }
    }

    .join-widget{

        p{
            color: #e8eaf3;
            font-size: 15px;
            margin-bottom: 25px;
        }
        input,
        button{
            width: 220px;
            display: block;
            height: 50px;
            background: $white;
            border: 0;
            padding: 10px;
            color: $dark-gray;
            border-radius: 30px 0px 30px 30px;
            text-align: center;

            &:focus{
                outline: none;
            }
        }



        button{
            margin-top: 20px;
            color: $dark-gray;
            padding-right: 30px;
            font-weight: 800;
            text-align: center;
            position: relative;
            background: $theme-primary-color;
            border-radius: 30px 30px 30px 0px;

            i{
                margin-left: 15px;
            }
        }


    }

  
    .wpo-lower-footer{
        text-align: center;
        position: relative;
        background: #192f8b;
    
        .row{
            padding: 20px 0;
            position: relative;
        }
    
        .copyright{
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
    
            a{
                color: #e8eaf3;
                text-decoration: none;
            }
    
            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }
    
}






