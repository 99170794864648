/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,600,700,800&display=swap");

// fonts
$base-font-size: 15;
$base-font: "Muli", sans-serif;
$heading-font: "Muli", sans-serif;




// color
$dark-gray: #13287e;
$body-color: #4c5165;
$white: #fff;
$light: #8188a9;
$black: #000;
$cyan: #848892;

$theme-primary-color: #fde023;
$theme-primary-color-s2: #247ffb;
$body-bg-color: #fff;
$section-bg-color: #f6faff;
$text-color: #6e6e6e;
$text-light-color: #2c3147;
$heading-color: $dark-gray;
$border-color: #e1e1e1;
$border-color-s2: #d8e0f1;
